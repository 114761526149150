import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
// import Loading from '@/components/Loading'
import '@/styles/index.scss'
// import { useUserInfo } from './hooks/useUserInfo'

// 主页
const Main = lazy(() => import('./pages/Main'))

// 登录
// const Login = lazy(() => import('./pages/Login'))

const App = () => {
    // const { listener } = useUserInfo()

    // useEffect(() => {
    //     listener()
    // }, [listener])

    return (
        <Suspense>
            <Routes>
                <Route path="/*" element={<Main />} />
            </Routes>
        </Suspense>
    )
}

export default React.memo(App)
