const defaultConfig = {
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION
} as Types.Config

const mergeConfig = (): Types.Config => {
    if (process.env.REACT_APP_ENV === 'dev') {
        return Object.assign(defaultConfig, require('./dev'))
    }

    if (process.env.REACT_APP_ENV === 'test') {
        return Object.assign(defaultConfig, require('./test'))
    }
    return Object.assign(defaultConfig, require('./prd'))
}

export default mergeConfig()
