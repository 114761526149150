import React from 'react'
import ReactDOM from 'react-dom/client'
// import { ConfigProvider } from 'antd'
// import zh_CN from 'antd/es/locale/zh_CN'
import App from './App'
import './styles/index.scss'
// import 'antd/dist/reset.css'
// import { BrowserRouter } from 'react-router-dom'
import config from './config'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

console.log('config', config)

// root.render(
//     <React.StrictMode>
//         <ConfigProvider locale={zh_CN}>
//             <BrowserRouter>
//                 <App />
//             </BrowserRouter>
//         </ConfigProvider>
//     </React.StrictMode>
// )
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)

// root.render(<App />)
